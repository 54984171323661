@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 240, 5%, 64.9%;
    --muted-foreground: 210 40% 96.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 174.70000000000005, 83.9%, 31.6%;
    --primary-foreground: 210 40% 98%;

    --secondary: 175, 77%, 26%, 1;
    --secondary-foreground: 210 40% 98%;

    --accent: 175, 84%, 32%, 1;
    --accent-foreground: 210 40% 98%;

    --destructive: 0, 72.2%, 50.6%;
    --destructive-foreground: 210 40% 98%;

    --ring: 165, 20%, 65%;

    --radius: 0.375rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: rgb(249 250 251);
}
#__next {
  min-height: 99%;
}

a {
  color: inherit;
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.24, rgb(150, 150, 150)),
    color-stop(0.52, rgb(110, 135, 130)),
    color-stop(0.86, rgb(136, 135, 135))
  );
}

.table {
  border: 1px solid rgb(240, 240, 240);
  width: fit-content;
  overflow: scroll;
  max-width: 100%;
  margin: auto;
}

.table .tr :last-child .td {
  border-bottom: 0;
}
.table .th {
  background: white;
  text-transform: uppercase;
  font-size: var(--chakra-fontSizes-xs);
  font-weight: bold;
  color: var(--chakra-colors-gray-600);
}

.table > .th > * {
  padding: 0.5em;
}
.table .td {
  border-bottom: 1px solid rgba(240, 240, 240, 0.481);
}
.table .th,
.table .td {
  padding: 5px 20px;
  background-color: #fff;
  overflow: hidden;

  /* border-right: 1px solid rgb(240, 240, 240); */
}

.add-sticky {
  overflow: scroll;
}
.add-sticky .add-sticky {
  position: sticky;
  z-index: 1;
  width: fit-content;
}
.table.sticky .header {
  z-index: 4;
  top: 0;
  box-shadow: 0px 1px 1px #ccc;
  padding-bottom: 10px;
  background: white;
  margin-bottom: 12px;
}
.table.sticky [data-sticky-td] {
  position: sticky;
}
.table.sticky [data-sticky-last-left-td] {
  box-shadow: 2px 0px 3px #ccc;
}
.table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

.table.sticky [data-sticky-td] {
  position: sticky;
  min-height: 80px;
  margin: auto;
}

.table.sticky [data-sticky-last-left-td] {
  box-shadow: 1px 0px 1px rgb(240, 240, 240);
  background: rgb(250, 250, 250);
}
.table.sticky [data-sticky-last-left-td] input {
  z-index: 10;
}
.table.sticky [data-sticky-first-right-td] {
  box-shadow: 1px 0px 1px rgb(240, 240, 240);
}

.animate-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

[type="text"]:focus[id^="react-select"] {
  outline-offset: 0;
  box-shadow: none;
}
/* #react-select-5-input */

.close-button-dialog {
  display: none;
}

.scrollbar {
  /* padding-right: 4px;
  padding-left: 4px; */
  scrollbar-width: thin;
  scrollbar-color: #e2e8f0 #f7fafc;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f7fafc;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #bec3c7;
  border-radius: 20px;
  border: 3px solid #f7fafc;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #bec3c7;
}

.PhoneInputInput {
  border: none;
  outline: none;
  width: 100%;
  height: 2.5rem;
  background: white;
  font-size: 1rem;
  background-color: transparent;
}

.PhoneInputInput:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.dot-falling {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #359377;
  color: #359377;
  box-shadow: 9999px 0 0 0 #359377;
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before, .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #359377;
  color: #359377;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #359377;
  color: #359377;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(128, 255, 200, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #359377;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(128, 255, 200, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(128, 255, 200, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #359377;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(128, 255, 200, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(128, 255, 200, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #359377;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(128, 255, 200, 0);
  }
}